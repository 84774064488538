import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, Paper, Tooltip, Stack, Typography, Drawer, Autocomplete, TextField } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';

class CanteenDayend extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dayendBillData: [],
      totalAmt: '',
      totalCash: '',
      totalCard: '',
      totalUPI: '',
      totalBankTran: '',
      page: 0,
      pageSize: 10,
      isLoader: false,
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      UserListData: [],
      UserDetails: [],
      isErrorMsg: false,
      isErrorMsgText: '',
    }
  }

  componentDidMount() {
    this.GetUserDetails()
    this.getCanteenDayendBill()
  }

  GetUserDetails = () => {
    try {
      RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET + `?show_all=true`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              UserListData: response.data.data
            })
          }
        }).catch((e) => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getCanteenDayendBill = () => {
    try {
      let states = this.state
      let User_ID = []
      states.UserDetails.forEach(element => User_ID.push(element.id))
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_DAYENDBILL + `?from_date=${states.fromDate}&to_date=${states.toDate}&userid=${User_ID}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              dayendBillData: response.data.data?.data,
              totalAmt: response.data?.data?.total_amount,
              totalCash: response.data?.data?.total_cash,
              totalCard: response.data?.data.total_card,
              totalUPI: response.data?.data?.total_upi,
              totalBankTran: response.data?.data?.total_bank,
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  printCanteenDayend = () => {
    try {
      let states = this.state
      let User_ID = []
      states.UserDetails.forEach(element => User_ID.push(element.id))
      RestAPIService.PrintPDF(Serviceurls.CANTEEN_DAYENDBILL + `?from_date=${states.fromDate}&to_date=${states.toDate}&userid=${User_ID}&export_type=pdf`).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }
  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box className='eMed_Canteen_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Tooltip>
            <Button onClick={() => { this.setState({ filterOpen: false }) }}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
            </Button>
          </Tooltip>
        </Box>
        <Box className='eMed_Canteen_fil_content'>
          <Box className='eMed_Canteen_Date'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <Autocomplete
              multiple
              options={this.state?.UserListData.length > 0 ? this.state?.UserListData : []}
              getOptionLabel={(options) => `${options?.login_fname ? options.login_fname : ""} ${options?.login_lname ? options.login_lname : ""}`}
              onChange={(e, value) => {
                this.setState({
                  UserDetails: value
                })
              }}
              value={this.state.UserDetails}
              id="combo-box"
              sx={{ width: "14vw" }}
              renderInput={(params) => <TextField {...params} label={t('UserName')} size='small' />}
            />
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
            <Button size='small' variant='contained' className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
          </Stack>
        </Box>
      </Box>
    )
  }
  clearFilter = () => {
    this.setState({
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      UserDetails: [],
    }, () => {
      this.getCanteenDayendBill()
    })
  }
  searchFilter = () => {
    this.getCanteenDayendBill()
    this.setState({ filterOpen: false, })
  }

  renderTbl = () => {
    const { t } = this.props
    this.state.dayendBillData.forEach((element, index) => { element.sno = index + 1 })
    const columns = [
      {
        field: "bill_type", headerName: "Bill Type", flex: 0.15, align: "center", headerAlign: "center",
        renderCell: (params) => (params?.row?.bill_type ? params?.row?.bill_type : '-')
      },
      {
        field: "cash", headerName: "Cash", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.cash ? params?.row?.cash : '0')
      },
      {
        field: "card", headerName: "Card", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.card ? params?.row?.card : '0')
      },
      {
        field: "upi", headerName: "UPI", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.upi ? params?.row?.upi : '0')
      },
      {
        field: "bank", headerName: "Bank Transfer", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.bank ? params?.row?.bank : '0')
      },
      {
        field: "credit_value", headerName: "Patient Credit", flex: 0.25, type: "number",
        renderCell: (params) => (params?.row?.credit_value ? params?.row?.credit_value : '0')
      },
    ]
    return (
      <Box height={'64vh'}>
        <DataGrid
          rows={this.state.dayendBillData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          hideFooterSelectedRowCount
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          headerHeight={40}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowsPerPageOptions={[10, 20, 30]}
          disableSelectionOnClick
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ filterOpen: false })
  }
  render() {
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Canteen_TopCon'>
          <Box className='eMed_Canteen_CardCon'>
            {AmountsCard('Total Received Amount', this.state.totalAmt ? this.state.totalAmt : 0, false, "white", false, null, 'canteen_no_amount')}
            {AmountsCard('Cash', this.state.totalCash ? this.state.totalCash : 0, false, "white", false, null, 'canteen_cash')}
            {AmountsCard('Card', this.state.totalCard ? this.state.totalCard : 0, false, "white", false, null, 'canteen_card')}
            {AmountsCard('UPI', this.state.totalUPI ? this.state.totalUPI : 0, false, "white", false, null, 'canteen_upi')}
            {AmountsCard('Bank Transfer', this.state.totalBankTran ? this.state.totalBankTran : 0, false, "white", false, null, 'canteen_banktfr')}
          </Box>
          <Box className='eMed_Canteen_FilterCon'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button onClick={() => { this.setState({ filterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
            <Box sx={{ borderRight: '1px solid #616161', height: '2vw', marginBottom: '.4vw' }}></Box>
            <Tooltip title="Print" placement='top' arrow>
              <Button onClick={()=>{ this.printCanteenDayend()}}>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Paper className='eMed_Canteen_SecondCon' elevation={2}>
          {this.renderTbl()}
        </Paper>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
      </Box>
    )
  }
}
export default withTranslation()(CanteenDayend);