import React, { Component } from 'react';
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material';
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class HsnWise extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hsnData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "hsnWise": [{ label: "Hsncode Rate Wise", value: "ratewise" }
                    , { label: "Hsncode Wise", value: "codewise" }],
                "report_type": { label: "Hsncode Rate Wise", value: "ratewise" },
                'days':91
            },
            pageName: 'HsnWise',
            filterOpen: false,
            totalAmount: 0,
            taxableAmount: 0,
            gstAmt: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getHsnData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getHsnData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    getHsnData = () => {
        try {
            this.LoaderFunction(true);
            let {report_type} = this.state.filterDatas;
            RestAPIService.getAll(`${Serviceurls.LAB_SALES_HSN_WISE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&report_type=${report_type?.value ? report_type?.value : "ratewise"}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        let Data = response?.data;
                        this.setState({
                            hsnData: Data?.data?.length > 0 ? Data?.data : [],
                            taxableAmount: Data?.total_taxable_amount ? Data?.total_taxable_amount : 0,
                            totalAmount: Data?.total_net_amount ? Data?.total_net_amount : 0,
                            rowCount: Data?.total_count,
                            gstAmt: Data?.total_gst_amount ? Data?.total_gst_amount : 0,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getHsnExcel = () => {
        let {report_type} = this.state.filterDatas;
        try {
            this.setState({disableBtn: true})
            RestAPIService.excelGet(`${Serviceurls.LAB_SALES_HSN_WISE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&report_type=${report_type?.value ? report_type?.value : "ratewise"}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }

    getHsnPrint = () => {
        try {
        let {report_type} = this.state.filterDatas;
        this.setState({disableBtn: true})
          RestAPIService.getAllPrint(`${Serviceurls.LAB_SALES_HSN_WISE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&report_type=${report_type?.value ? report_type?.value : "ratewise"}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disableBtn: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disableBtn: false})
        }
      }

    render() {
        const { t } = this.props;
        let {report_type} = this.state.filterDatas;
        this.state.hsnData?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'hsn_code', headerName: "Hsn Code", flex: 0.12, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'}>
                    {row?.hsn_code?.length > 15 ? <Tooltip placement='top' title={row?.hsn_code} arrow>
                        <div>{row?.hsn_code?.slice(0, 15) + '...'}</div></Tooltip> :
                        row?.hsn_code ? row?.hsn_code : '-'}
                </Box>)
            },
            {
                field: 'gst_percentage', headerName: "Tax %", flex: 0.08, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.gst_percentage ? row?.gst_percentage : '0'}</Box>)
            },
            {
                field: 'total_qty', headerName: "Total Qty", flex: 0.08, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.total_qty ? row?.total_qty : '0'}</Box>)
            },
            {
                field: 'tax_value', headerName: `${"Taxable Amount"}`, flex: 0.09, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.tax_value ? AmountFormat(row?.tax_value).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'cgst_amt', headerName: `${"CGST Amount"}`, flex: 0.08, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_amt ? AmountFormat(row?.cgst_amt).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'sgst_amt', headerName: `${"SGST Amount"}`, flex: 0.08, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_amt ? AmountFormat(row?.sgst_amt).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'total', headerName: `${"Total Amount"}`, flex: 0.09, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.total ? AmountFormat(row?.total).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
        ]

        if(report_type?.value === "codewise"){
            columns.splice(1,1);
        }
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Taxable Amount", this.state.taxableAmount, false, "white", false, null, 'rpt_hsncoderate_tax_amt')}
                        {AmountsCard("Total GST Amount", this.state.gstAmt, false, "white", false, null, 'hsn_sale_gst_amt')}
                        {AmountsCard("Total Amount", this.state.totalAmount, false, "white", false, null, 'rpt_hsncoderate_total_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_hsncoderate_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_hsncoderate_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getHsnPrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_hsncoderate_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getHsnExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.hsnData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(HsnWise)
